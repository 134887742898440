export type AnyObservableType = unknown;

const accessedObservableCollector = new Set<Observable<AnyObservableType>>();

/**
 * A wrapper class around another object or primitive that can be subscribed to
 * and get notified when the inner value changes.
 */
export class Observable<T> {
  private observers = new Set<(map: T) => void>();
  private currentValue: T;
  private static collectAccessedObservables = false;

  static startCollectingAccessedObservables(): void {
    Observable.collectAccessedObservables = true;
  }

  static stopCollectingAccessedObservables(): Observable<AnyObservableType>[] {
    Observable.collectAccessedObservables = false;
    const accessed = Array.from(accessedObservableCollector);
    accessedObservableCollector.clear();
    return accessed;
  }

  /**
   * Creates an instance of Observable.
   *
   * @param initialValue The starting value of the observable.
   */
  constructor(initialValue: T) {
    this.currentValue = initialValue;
  }

  /**
   * Updates the current value of the observable and notifies subscribers.
   *
   * @param value The next value.
   */
  next(value: T): void {
    this.currentValue = value;
    this.notifySubscribers();
  }

  /**
   * Subscribes to all changes to this observable.
   *
   * @param callback Function to be called when the observable changes.
   * @returns {Function} An unsubscribe function.
   */
  subscribe(callback: (currentValue: T) => void): () => void {
    this.observers.add(callback);
    return (): void => {
      this.observers.delete(callback);
    };
  }

  /**
   * The current value of this observable.
   */
  get value(): T {
    if (Observable.collectAccessedObservables) {
      accessedObservableCollector.add(this as Observable<AnyObservableType>);
    }
    return this.currentValue;
  }

  private notifySubscribers() {
    this.observers.forEach((observer) => observer(this.value));
  }
}

export const observable = <T>(obj: T): Observable<T> => new Observable(obj);
