import { Component, ComponentClass, ComponentType, h } from "preact";

import { AnyObservableType, Observable } from "utils/observable/Observable";
import { proxyObjectAndNotifyOnObservableAccess } from "utils/observable/proxyObjectAndNotifyOnObservableAccess";

// eslint-disable-next-line @typescript-eslint/ban-types
export const observer = <P extends {}>(
  WrappedComponent: ComponentType<P>
): ComponentClass<P> => {
  return class extends Component<P> {
    displayName = WrappedComponent.displayName;
    private unsubscribeFns: (() => void)[] = [];

    constructor() {
      super();
    }

    componentWillUnmount(): void {
      this.unsubscribeAll();
    }

    render(): h.JSX.Element {
      return <WrappedComponent {...this.proxyProps()} />;
    }

    private proxyProps(): P {
      const props = this.props;
      if (!props) return props;
      this.unsubscribeAll();
      const objectClone: Partial<P> = {};
      const subscribeFn = this.subscribeToAccessedObservables();

      for (const prop of Reflect.ownKeys(props).filter(
        (key) => typeof key === "string"
      )) {
        const ogProp = props[prop as keyof P];
        objectClone[prop as keyof P] = ogProp;
        if (ogProp instanceof Object) {
          const { revoke, proxy } = proxyObjectAndNotifyOnObservableAccess(
            ogProp,
            subscribeFn
          );
          objectClone[prop as keyof P] = proxy;
          this.unsubscribeFns.push(revoke);
        } else {
          objectClone[prop as keyof P] = ogProp;
        }
      }

      return objectClone as P;
    }

    private subscribeToAccessedObservables(): (
      accessed: Observable<AnyObservableType>
    ) => void {
      return (accessed: Observable<AnyObservableType>): void => {
        const unsubscribeFn = accessed.subscribe(() => {
          this.forceUpdate();
        });
        this.unsubscribeFns.push(unsubscribeFn);
      };
    }

    private unsubscribeAll(): void {
      this.unsubscribeFns.forEach((unsubscribeFn) => {
        unsubscribeFn();
      });
    }
  };
};
