/** Generic types that a file can be. */
export enum ResourceContentType {
  AUDIO_FILE = "audio_file",
  IMAGE_FILE = "image_file",
  TEXT_FILE = "text_file",
  VIDEO_FILE = "video_file",
  VIMEO = "vimeo",
  UNKNOWN = "unknown",
}

const AUDIO_FILE_REGEX = /\.(wav|aiff|mp3|mp4|aac|ogg|wma|flac|alac|m4a)$/i;
const IMAGE_FILE_REGEX = /\.(jpg|jpeg|png|webp|svg|tif|tiff|ico|gif|exif|bmp)$/i;
const TEXT_FILE_REGEX = /\.(doc|docx|pdf|rtf|pages|txt)$/i;
const VIDEO_FILE_REGEX = /\.(3g2|3gp|aaf|asf|avchd|avi|drc|flv|m2v|m4p|m4v|mkv|mng|mov|mp2|mp4|mpe|mpeg|mpg|mpv|mxf|nsv|ogg|ogv|qt|rm|rmvb|roq|svi|vob|webm|wmv)$/i;
const VIMEO_FILE_REGEX = /vimeo\.com/i;

/**
 * @param uri resource file name or path.
 *
 * @returns {ResourceContentType} generic content type of the given path.
 */
export function getResourceContentType(uri: string): ResourceContentType {
  if (AUDIO_FILE_REGEX.exec(uri)) {
    return ResourceContentType.AUDIO_FILE;
  }
  if (IMAGE_FILE_REGEX.exec(uri)) {
    return ResourceContentType.IMAGE_FILE;
  }
  if (TEXT_FILE_REGEX.exec(uri)) {
    return ResourceContentType.TEXT_FILE;
  }
  if (VIDEO_FILE_REGEX.exec(uri)) {
    return ResourceContentType.VIDEO_FILE;
  }
  if (VIMEO_FILE_REGEX.exec(uri)) {
    return ResourceContentType.VIMEO;
  }
  return ResourceContentType.UNKNOWN;
}
