import { FunctionalComponent, h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";

import style from "./style.scss";

interface Props {
  isVisible: boolean;
  message?: string;
  onHideComplete?: () => void;
}

const TOTAL_STEPS = 4;
const DURATION_PER_STEP_MS = 400;

const Loader: FunctionalComponent<Props> = (props: Props) => {
  const pendingVisibilityTimeout = useRef(0);
  const [direction, setDirection] = useState<"asc" | "desc">("asc");
  const [iteration, setIteration] = useState(0);
  const [step, setStep] = useState(1);
  const [visible, setVisible] = useState(props.isVisible);
  const segmentCount = Math.pow(3, step - 1);
  const segments = new Array(segmentCount).fill(undefined);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const pendingTimeout = window.setTimeout(() => {
      let nextStep: number;
      if (direction === "asc") {
        if (step >= TOTAL_STEPS) {
          nextStep = TOTAL_STEPS - 1;
          setDirection("desc");
        } else {
          nextStep = step + 1;
        }
      } else {
        if (step <= 1) {
          nextStep = 2;
          setDirection("asc");
        } else {
          nextStep = step - 1;
        }
      }
      setIteration(iteration + 1);
      setStep(nextStep);
    }, DURATION_PER_STEP_MS);

    return () => {
      window.clearTimeout(pendingTimeout);
    };
  }, [direction, iteration, step]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.clearTimeout(pendingVisibilityTimeout.current);
    if (visible && !props.isVisible) {
      pendingVisibilityTimeout.current = window.setTimeout(() => {
        setVisible(false);
      }, 1000);
    } else if (!visible && props.isVisible) {
      setVisible(true);
    }

    return () => {
      window.clearTimeout(pendingVisibilityTimeout.current);
    };
  }, [props.isVisible, visible]);

  return (
    <div
      class={style.root}
      data-visible={String(visible)}
      onTransitionEnd={onTransitionEnd}
    >
      <div class={style.content}>
        <div class={style.graphic} data-step={step}>
          {segments.map((s, i) => (
            <div key={i} class={style.graphicSegment} />
          ))}
        </div>
        <div class={style.message}>
          {props.message ?? "Loading game"}
          <span class={style.messageEllipsis}>
            {".".repeat((iteration % 3) + 1)}
          </span>
        </div>
      </div>
    </div>
  );

  function onTransitionEnd() {
    if (!visible && props.onHideComplete) {
      props.onHideComplete();
    }
  }
};

export default Loader;
