import { AnyObservableType, Observable } from "utils/observable/Observable";

/**
 * Returns the provided object with a proxy in front of it that notifies for
 * every new child observable that gets accessed
 *
 * @param objectToProxy The class instance/object that may contain observables
 * @param accessCallback callback that gets called every time a new
 * observable is accessed
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function proxyObjectAndNotifyOnObservableAccess<T extends {}>(
  objectToProxy: T,
  accessCallback: (o: Observable<AnyObservableType>) => void
): { proxy: T; revoke: () => void } {
  const trackedObservables = new Set<Observable<unknown>>();
  // TODO: fallback behavior for IE?
  const proxy = Proxy.revocable(objectToProxy, {
    get(target, name, receiver): unknown {
      const value = Reflect.get(target, name, receiver) as unknown;
      // Every time a property of this class is accessed and the value
      // is an observable that isn't already tracked, trigger the
      // callback
      if (value instanceof Observable && !trackedObservables.has(value)) {
        trackedObservables.add(value);
        accessCallback(value);
      }
      return value;
    },
  });
  return proxy;
}
