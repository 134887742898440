import { ComponentType, FunctionComponent, h } from "preact";
import { Subtract } from "utility-types";
import { useContext } from "preact/hooks";

import { observer } from "./observer";

import { AppStore } from "stores/AppStore";
import { StoreContext } from "contexts";

export interface WithAppStoreProps {
  store: AppStore;
}

/**
 * HOC to wrap a component with the appStore
 *
 * @param WrappedComponent the component to wrap
 */
export const withStore = <Props extends WithAppStoreProps>(
  WrappedComponent: ComponentType<Props>
): FunctionComponent<Subtract<Props, WithAppStoreProps>> => {
  const WithStoreComponent = (
    props: Subtract<Props, WithAppStoreProps>
  ): h.JSX.Element => {
    const Observed = observer(WrappedComponent);
    const store = useContext(StoreContext);
    const withStoreProps = {
      store,
      ...props,
    };

    return <Observed {...(withStoreProps as Props)} />;
  };

  return WithStoreComponent;
};
