import { FunctionalComponent, h } from "preact";

import Loader from "components/loader";
import { withStore } from "components/hocs/withStore";

import { LoadingState } from "stores/AppStore";
import { useState } from "preact/hooks";

const AppLoader: FunctionalComponent = withStore((props) => {
  const { store } = props;
  const [loaderShouldShow, setLoaderShouldShow] = useState(
    store.loadingState === LoadingState.LOADING
  );

  if (store.loadingState === LoadingState.LOADING && !loaderShouldShow) {
    setLoaderShouldShow(true);
  }

  if (!loaderShouldShow) return null;

  return (
    <Loader
      isVisible={store.loadingState === LoadingState.LOADING}
      onHideComplete={() => {
        setLoaderShouldShow(false);
      }}
    />
  );
});

export default AppLoader;
