import { Observable } from "./Observable";

/**
 * A type of Observable that automatically subscribes to any observables
 * accessed during execution of the provided function, and emits any time of
 * the accessed observables changes.
 *
 * @augments {Observable<void>}
 */
export class Reaction extends Observable<void> {
  private readonly unsubscribeFns = new Set<() => void>();

  /**
   * Creates an instance of Reaction.
   *
   * @param fnToObserve a function which immediately executes. All observables
   * that are accessed are immediately subscribed to.
   */
  constructor(fnToObserve: () => void) {
    super();
    Observable.startCollectingAccessedObservables();
    fnToObserve();
    const accessedObservables = Observable.stopCollectingAccessedObservables();
    for (const observable of accessedObservables) {
      const unsub = observable.subscribe(() => {
        this.next();
      });
      this.unsubscribeFns.add(unsub);
    }
  }

  /** @override */
  subscribe(callback: (map: void) => void): () => void {
    const superUnsub = super.subscribe(callback);

    return () => {
      superUnsub();
      this.unsubscribeFns.forEach((unsub) => unsub());
    };
  }
}
