import { ArtistModel } from "./ArtistModel";
import { MediumModel } from "./MediumModel";
import { WorkPieceModel } from "./WorkPieceModel";

import { WorkApiResponse, WorkPieceApiResponse } from "data/api";
import { AppStore } from "stores/AppStore";

import { memoize } from "utils/observable/memoize";
import { routes } from "utils/urls";

/**
 * Model representing a work of art.
 */
export class WorkModel {
  id: string;
  private readonly artistId: string;
  readonly code: WorkApiResponse["code"];
  private readonly mediumId: string;
  private readonly parentWorkIds: string[];
  private readonly piecesIndex: WorkApiResponse["pieces"];
  private readonly piecesFull = new Map<string, WorkPieceModel>();
  readonly status: WorkApiResponse["status"];
  private readonly store: AppStore;
  readonly title: string;

  constructor(response: WorkApiResponse, store: AppStore) {
    this.id = response.id;
    this.artistId = response.artist;
    this.code = response.code;
    this.mediumId = response.medium;
    this.parentWorkIds = response.parents;
    this.piecesIndex = response.pieces;
    this.title = response.title;
    this.status = response.status;
    this.store = store;
  }

  @memoize()
  get artist(): ArtistModel | null {
    return this.store.getArtistById(this.artistId);
  }

  @memoize()
  get childWorks(): WorkModel[] {
    return this.store.works.filter((work) => work.parentWorks.includes(this));
  }

  @memoize()
  get isOriginalMessage(): boolean {
    return this.parentWorks.length === 0;
  }

  @memoize()
  get generation(): number {
    // If the work has no parents or children, it's a straggler, and doesn't
    // get a generation.
    if (!this.childWorks.length && !this.parentWorks.length) {
      return -1;
    }
    // If there are no parents, it must be the starting node.
    if (this.parentWorks.length === 0) {
      return 1;
    }
    // Otherwise the generation is determined as the greatest of either a) the
    // deepest path back to the origin node or b) the smallest child work
    // generation minus one. The latter is because some works skip a round and
    // those should be "pulled" to a later round (to appear as a direct parent
    // of the child work).
    const ancestorDepth = getAncestorDepth(this);
    const minChildAncestorDepth = this.childWorks.length
      ? Math.min(...this.childWorks.map((work) => work.generation))
      : 0;
    return Math.max(ancestorDepth, minChildAncestorDepth - 1);
  }

  @memoize()
  get link(): string | null {
    if (!this.artist) return null;

    return routes.artistDetail.toLink({
      slug: this.artist.slug,
    });
  }

  @memoize()
  get medium(): MediumModel | null {
    return this.store.getMediumById(this.mediumId);
  }

  getPiece(
    placement: WorkPieceApiResponse["placement"]
  ): WorkPieceModel | null {
    const pieceSummary = this.piecesIndex.find(
      (piece) => piece.placement === placement
    );
    if (!pieceSummary) return null;

    return this.piecesFull.get(pieceSummary.id) ?? null;
  }

  getPieces(placement?: WorkPieceApiResponse["placement"]): WorkPieceModel[] {
    const pieces: WorkPieceModel[] = [];
    const pieceSummaries = placement
      ? this.piecesIndex.filter((piece) => piece.placement === placement)
      : this.piecesIndex;

    if (pieceSummaries.length < 1) return pieces;

    for (const p of pieceSummaries) {
      const fullPiece = this.piecesFull.get(p.id);
      if (fullPiece) {
        pieces.push(fullPiece);
      }
    }
    return pieces.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();

      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  }

  async loadPieces(
    placements?: WorkPieceApiResponse["placement"][]
  ): Promise<WorkPieceModel[]> {
    let pieces = this.piecesIndex;
    const fullPieces: WorkPieceModel[] = [];
    if (placements) {
      pieces = this.piecesIndex.filter((piece) =>
        placements.includes(piece.placement)
      );
    }
    await Promise.all(
      pieces.map(async (piece) => {
        const loadedPiece = this.piecesFull.get(piece.id);
        if (loadedPiece) {
          fullPieces.push(loadedPiece);
          return loadedPiece;
        }
        try {
          const fullPiece = await this.store.getWorkPieceById(
            piece.id,
            piece.modified_date
          );
          this.piecesFull.set(piece.id, fullPiece);
          fullPieces.push(fullPiece);
          return fullPiece;
        } catch (error) {
          console.error(`Failed to load piece with id ${piece.id}`);
        }
      })
    );

    return fullPieces;
  }

  @memoize()
  get parentWorks(): WorkModel[] {
    return this.parentWorkIds
      .map((parentWorkId) => this.store.getWorkById(parentWorkId))
      .filter((work) => !!work) as WorkModel[];
  }

  @memoize()
  get siblingWorks(): WorkModel[] {
    return this.store.works.filter(
      (work) =>
        work !== this &&
        this.parentWorks.some((parentWork) =>
          work.parentWorks.includes(parentWork)
        )
    );
  }

  get visited(): boolean {
    return this.store.visitedWorks$.value.has(this);
  }
}

function getAncestorDepth(parentWork: WorkModel, currentDepth = 1): number {
  if (parentWork.parentWorks.length === 0) return currentDepth;
  return Math.max(
    ...parentWork.parentWorks.map((work) =>
      getAncestorDepth(work, currentDepth + 1)
    )
  );
}
