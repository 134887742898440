interface RouteSegment {
  name: string;
  optional?: boolean;
  variable?: boolean;
}

type KeyOfMap<M extends Map<unknown, unknown>> = M extends Map<infer K, unknown>
  ? K
  : never;

class Route {
  private readonly segmentMap = new Map<string, RouteSegment>();
  private readonly segments: RouteSegment[];

  constructor(segments: RouteSegment[]) {
    this.segments = segments;
    for (const segment of segments) {
      this.segmentMap.set(segment.name, segment);
    }
  }

  toRouterString() {
    return (
      "/" +
      this.segments
        .map((s) => `${s.variable ? ":" : ""}${s.name}${s.optional ? "?" : ""}`)
        .join("/")
    );
  }

  toLink(
    args?: Record<KeyOfMap<Route["segmentMap"]>, string>,
    query?: { [key: string]: string }
  ): string {
    let queryString = "";
    if (query) {
      queryString =
        "?" +
        Object.keys(query)
          .map((k) => `${k}=${query[k]}`)
          .join("&");
    }
    return (
      "/" +
      this.segments
        .map((s) => {
          if (s.variable && args && args[s.name]) {
            return args[s.name];
          }
          if (s.variable && s.optional && (!args || !args[s.name])) {
            return undefined;
          }
          return s.name;
        })
        .filter((s) => !!s)
        .join("/") +
      queryString
    );
  }
}

export const routes = {
  home: new Route([]),
  about: new Route([
    {
      name: "about",
    },
  ]),
  artistDetail: new Route([
    {
      name: "artists",
    },
    {
      name: "slug",
      variable: true,
    },
  ]),
  credits: new Route([
    {
      name: "credits",
    },
  ]),
  emailUpdates: new Route([
    {
      name: "email-updates",
    },
  ]),
  essayDetail: new Route([
    {
      name: "essays",
    },
    {
      name: "slug",
      variable: true,
    },
  ]),
  exhibit: new Route([
    {
      name: "exhibit",
    },
  ]),
  search: new Route([
    {
      name: "search",
    },
    {
      name: "view",
      optional: true,
      variable: true,
    },
    {
      name: "query",
      optional: true,
      variable: true,
    },
  ]),
};
