import { Reaction } from "./ObservableReaction";

type ArgsID = string;
type FnResult = unknown;
// eslint-disable-next-line @typescript-eslint/ban-types
type Cache<ClassInstance extends object> = WeakMap<
  ClassInstance,
  Map<ArgsID, FnResult>
>;

const argsIDResolver = (args: unknown[]) => {
  return args.map((arg) => `${typeof arg}__${String(arg)}`).join(",");
};

/**
 * A decorator that can be applied to a class getter method to memoize its
 * return value and automatically mark the value as stale if any dependent
 * observables change.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function memoize<T extends object>(): (
  target: T,
  propertyKey: string,
  descriptor: PropertyDescriptor
) => PropertyDescriptor {
  return function (
    target: T,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    if (
      typeof descriptor.get !== "function" &&
      typeof descriptor.value !== "function"
    ) {
      throw new Error("Memoization can be applied only to methods or getters");
    }
    const getterOrMethod =
      typeof descriptor.get === "function" ? "get" : "value";
    const cache: Cache<T> = new WeakMap();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const originalMethod = descriptor[getterOrMethod] as (
      ...args: unknown[]
    ) => unknown;

    descriptor[getterOrMethod] = function (this: T, ...args: unknown[]) {
      if (!cache.has(this)) {
        cache.set(this, new Map());
      }
      const instanceCache = cache.get(this);
      const argsID = argsIDResolver(args);
      if (instanceCache?.has(argsID)) {
        return instanceCache.get(argsID);
      }
      const reaction = new Reaction(() => {
        const result = originalMethod.apply(this, args);
        instanceCache?.set(argsID, result);
      });

      const reactionUnsubscribeFn = reaction.subscribe(() => {
        instanceCache?.delete(argsID);
        if (!this) {
          reactionUnsubscribeFn();
        }
      });
      return instanceCache?.get(argsID);
    };
    return descriptor;
  };
}
