import { throttle } from "utils/throttle";

/**
 * Sets a CSS variable for tracking the actual available space. Used for mobile
 * where the `vh` unit does not subtract the height of the browser search bar.
 */
export function trueViewportHeight(): () => void {
  updateViewportHeight();

  const throttledUpdate = throttle(updateViewportHeight, 60);

  window.addEventListener("resize", throttledUpdate);

  return () => {
    window.removeEventListener("resize", throttledUpdate);
  };
}

function updateViewportHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty(
    "--available-viewport-height",
    `${vh}px`
  );
}
