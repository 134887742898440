import { ApiResponse, EssayApiResponse, WorkPieceApiResponse } from "data/api";

import { ArtistModel } from "models/ArtistModel";
import { EssayExcerptModel } from "models/EssayExcerptModel";
import { EssayModel } from "models/EssayModel";
import { MediumModel } from "models/MediumModel";
import { WorkModel } from "models/WorkModel";
import { WorkPieceModel } from "models/WorkPieceModel";

import { Distance, sumDistance } from "utils/geo/geo";
import { memoize } from "utils/observable/memoize";
import { observable } from "utils/observable/Observable";

const HISTORY_STORAGE_KEY = "telephone_history";

export enum LoadingState {
  LOADING = "loading",
  COMPLETE = "complete",
  ERROR = "error",
}

/**
 * Maintains application state.
 */
export class AppStore {
  private _data?: ApiResponse;
  readonly exhibitPath$ = observable<WorkModel[]>([]);
  readonly loadingState$ = observable(LoadingState.LOADING);
  readonly isNavExpanded$ = observable(false);
  readonly artists$ = observable(new Map<string, ArtistModel>());
  readonly essayExcerpts$ = observable(new Map<string, EssayExcerptModel>());
  readonly essays$ = observable(new Map<string, EssayModel>());
  readonly mediums$ = observable(new Map<string, MediumModel>());
  readonly syncDate$ = observable<Date | null>(null);
  readonly visitedWorks$ = observable(new Set<WorkModel>());
  readonly works$ = observable(new Map<string, WorkModel>());
  private slugToArtistMap = new Map<string, ArtistModel>();

  async init(): Promise<AppStore> {
    try {
      this._data = (await import("data/data.json")) as ApiResponse;
    } catch (error) {
      this.loadingState$.next(LoadingState.ERROR);
      return this;
    }

    const artistsMap = this.artists$.value;
    const essayExcerptsMap = this.essayExcerpts$.value;
    const mediumsMap = this.mediums$.value;
    const worksMap = this.works$.value;
    this._data.artists.forEach((artist) => {
      const constructedArtist = new ArtistModel(artist, this);
      artistsMap.set(artist.id, constructedArtist);

      artist.slugs.forEach((slug) => {
        this.slugToArtistMap.set(slug, constructedArtist);
      });
    });
    this._data.essays.forEach((essayExcerpt) =>
      essayExcerptsMap.set(essayExcerpt.id, new EssayExcerptModel(essayExcerpt))
    );
    this._data.mediums.forEach((medium) =>
      mediumsMap.set(medium.id, new MediumModel(medium))
    );
    this._data.works.forEach((work) =>
      worksMap.set(work.id, new WorkModel(work, this))
    );
    this.artists$.next(artistsMap);
    this.essayExcerpts$.next(essayExcerptsMap);
    this.mediums$.next(mediumsMap);
    this.works$.next(worksMap);
    this.syncDate$.next(new Date(this._data.sync_date));
    const firstWork = this.worksByGeneration[0][0];
    this.exhibitPath$.next([firstWork]);
    this.loadDataFromLocalStorage();
    this.loadingState$.next(LoadingState.COMPLETE);
    return this;
  }

  @memoize()
  get artists(): ArtistModel[] {
    const values: ArtistModel[] = [];
    this.artists$.value.forEach((artist) => values.push(artist));
    return values;
  }

  @memoize()
  get essayExcerpts(): EssayExcerptModel[] {
    const values: EssayExcerptModel[] = [];
    this.essayExcerpts$.value.forEach((essayExcerpt) =>
      values.push(essayExcerpt)
    );

    values.sort((a, b) => {
      const aTitle = a.title.toLowerCase().replace(/\W/g, "");
      const bTitle = b.title.toLowerCase().replace(/\W/g, "");

      if (a.featured && !b.featured) return -1;
      if (b.featured && !a.featured) return 1;
      if (aTitle < bTitle) return -1;
      if (aTitle > bTitle) return 1;
      return 0;
    });

    return values;
  }

  get exhibitPath(): WorkModel[] {
    return this.exhibitPath$.value;
  }

  get loadingState(): LoadingState {
    return this.loadingState$.value;
  }

  get isNavExpanded(): boolean {
    return this.isNavExpanded$.value;
  }

  @memoize()
  get mediums(): MediumModel[] {
    const values: MediumModel[] = [];
    this.mediums$.value.forEach((work) => values.push(work));

    values.sort((a, b) => {
      const aName = a.name.toLowerCase();
      const bName = b.name.toLowerCase();

      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });

    return values;
  }

  get syncDate(): Date | null {
    return this.syncDate$.value;
  }

  @memoize()
  get totalGenerations(): number {
    return Math.max(...this.works.map((work) => work.generation));
  }

  @memoize()
  get works(): WorkModel[] {
    const values: WorkModel[] = [];
    this.works$.value.forEach((work) => values.push(work));
    return values;
  }

  @memoize()
  get worksByGeneration(): WorkModel[][] {
    const totalGenerations = this.totalGenerations;
    const returnVal: WorkModel[][] = [];
    for (let gen = 1; gen <= totalGenerations; gen++) {
      returnVal.push(this.works.filter((work) => work.generation === gen));
    }
    return returnVal;
  }

  addWorkToUserVisitedHistory(work: WorkModel): void {
    if (this.visitedWorks$.value.has(work)) return;
    this.visitedWorks$.next(this.visitedWorks$.value.add(work));
    this.saveDataToLocalStorage();
  }

  // TODO: this could use some tests.
  updateOrStartNewPath(
    work: WorkModel,
    direction: "forward" | "backward" = "forward"
  ): void {
    const isWorkInCurrentPath = this.exhibitPath.includes(work);

    // If the work is in the current path, leave the path alone.
    if (isWorkInCurrentPath) return;

    // If it is not in the current path, travel the full path and check if this
    // work is a parent or child of any work in the path. If so, break the path
    // at that point and either append prepend, or replace the work, depending
    // on the relationship.
    let i = direction === "backward" ? this.exhibitPath.length - 1 : 0;

    while (this.exhibitPath[i]) {
      const currentPathWork = this.exhibitPath[i];
      const isChildOfCurrentPathWork = currentPathWork.childWorks.includes(
        work
      );
      const isParentOfCurrentPathWork = currentPathWork.parentWorks.includes(
        work
      );

      // If this work is a child work, we take a slice of the current path from
      // the start up to the parent of this work, then append this work (the
      // child).
      if (isChildOfCurrentPathWork) {
        const nextPath = this.exhibitPath.slice(0, i + 1).concat(work);
        this.exhibitPath$.next(nextPath);
        return;
      }

      // If this work is a parent work, we take a slice of the current path from
      // the child up to the end of the path, and prepend the new work (the
      // parent).
      if (isParentOfCurrentPathWork) {
        const nextPath = [work].concat(
          this.exhibitPath.slice(i, this.exhibitPath.length)
        );
        this.exhibitPath$.next(nextPath);
        return;
      }

      if (direction === "backward") {
        i--;
      } else {
        i++;
      }
    }

    // If no other condition was met, start a new path from this work.
    this.exhibitPath$.next([work]);
  }

  changeExhibitPathFrom(from: WorkModel, work: WorkModel): void {
    const fromIndex = this.exhibitPath.indexOf(from);
    if (fromIndex >= 0 && from.childWorks.includes(work)) {
      const pathStart = this.exhibitPath.slice(0, fromIndex + 1);
      this.exhibitPath$.next(pathStart.concat(work));
    }
  }

  restartExhibitPath(): void {
    const firstWork = this.worksByGeneration[0][0];

    if (firstWork) {
      this.exhibitPath$.next([firstWork]);
    }
  }

  getArtistById(artistId: string): ArtistModel | null {
    return this.artists$.value.get(artistId) ?? null;
  }

  @memoize()
  getArtistBySlug(artistSlug: string): ArtistModel | null {
    return this.slugToArtistMap.get(artistSlug) ?? null;
  }

  getDistanceAlongExhibitPathFrom(from: WorkModel): Distance {
    const zeroDistance = {
      meters: 0,
      miles: 0,
      kilometers: 0,
    };
    const fromIndex = this.exhibitPath.indexOf(from);
    if (fromIndex < 1) return zeroDistance;
    let traveled = zeroDistance;
    for (let i = fromIndex; i > 0; i--) {
      const { artist } = this.exhibitPath[i];
      const prevArtist = this.exhibitPath[i - 1].artist;
      if (!artist || !prevArtist) continue;
      traveled = sumDistance([traveled, artist.distanceFromArtist(prevArtist)]);
    }

    return traveled;
  }

  getMediumById(mediumId: string): MediumModel | null {
    return this.mediums$.value.get(mediumId) ?? null;
  }

  getWorkById(workId: string): WorkModel | null {
    return this.works$.value.get(workId) ?? null;
  }

  async getEssayById(essayId: string): Promise<EssayModel | null> {
    const cachedEssay = this.essays$.value.get(essayId);

    if (cachedEssay) {
      return cachedEssay;
    }

    try {
      const resp = await fetch(`/assets/essays/${essayId}.json`);
      const essayResponse = (await resp.json()) as EssayApiResponse;
      const essay = new EssayModel(essayResponse);
      this.essays$.next(this.essays$.value.set(essayId, essay));

      return essay
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getEssayBySlug(slug: string): Promise<EssayModel | null> {
    const excerpt = this.essayExcerpts.find(
      (essayExcerpt) => essayExcerpt.slug === slug
    );

    if (!excerpt) return null;

    return await this.getEssayById(excerpt.id);
  }

  async getWorkPieceById(
    pieceId: string,
    modifiedDate: string
  ): Promise<WorkPieceModel> {
    const resp = await fetch(`/assets/pieces/${pieceId}/${modifiedDate}.json`);
    const piece = (await resp.json()) as WorkPieceApiResponse;
    return new WorkPieceModel(piece, this);
  }

  setIsNavExpanded(isExpanded: boolean): void {
    this.isNavExpanded$.next(isExpanded);
  }

  private loadDataFromLocalStorage() {
    if (typeof window !== "undefined") {
      try {
        const dataString = window.localStorage.getItem(HISTORY_STORAGE_KEY);
        if (!dataString) return;
        const data = JSON.parse(dataString) as string[];
        const visitedWorks = data
          .map((workId) => this.works$.value.get(workId))
          .filter((work) => !!work) as WorkModel[];
        this.visitedWorks$.next(new Set(visitedWorks));
      } catch (error) {
        console.error("Failed to load user history from localStorage");
      }
    }
  }

  private saveDataToLocalStorage() {
    if (typeof window !== "undefined") {
      try {
        const savedWorksList: string[] = [];
        this.visitedWorks$.value.forEach((work) => {
          savedWorksList.push(work.id);
        });
        window.localStorage.setItem(
          HISTORY_STORAGE_KEY,
          JSON.stringify(savedWorksList)
        );
      } catch (error) {
        console.error("Failed to save data to local storage");
      }
    }
  }
}
