import { FunctionalComponent, h } from "preact";

import styles from "./style.scss";

import { routes } from "utils/urls";
import { withStore } from "components/hocs/withStore";

const Header: FunctionalComponent = withStore((props) => {
  const { store } = props;
  const onLinkClick = () => {
    store.setIsNavExpanded(false);
  };

  return (
    <header class={styles.root}>
      <a href="/" class={styles.logo} onClick={onLinkClick}>
        <span class={styles.logo__text}>Telephone</span>
      </a>
      <button
        class={styles.navToggle}
        type="button"
        onClick={() => store.setIsNavExpanded(!store.isNavExpanded)}
      >
        {store.isNavExpanded ? "Close" : "Menu"}
      </button>
      <nav class={store.isNavExpanded ? styles.navExpanded : styles.nav}>
        <div class={styles.nav__links}>
          <a
            class={styles.nav__link}
            href={routes.exhibit.toLink()}
            onClick={onLinkClick}
          >
            Exhibit
          </a>
          <a
            class={styles.nav__link}
            href={routes.search.toLink()}
            onClick={onLinkClick}
          >
            Search
          </a>
          <a
            class={styles.nav__link}
            href={routes.about.toLink()}
            onClick={onLinkClick}
          >
            About
          </a>
          <a
            class={styles.nav__link}
            href={routes.credits.toLink()}
            onClick={onLinkClick}
          >
            Credits
          </a>
        </div>
      </nav>
    </header>
  );
});

export default Header;
