import { WorkModel } from "./WorkModel";

import {
  ResourceContentType,
  getResourceContentType,
} from "utils/resource/resourceContentType";
import { AppStore } from "stores/AppStore";
import { WorkPieceApiResponse } from "data/api";
import { memoize } from "utils/observable/memoize";

/**
 * Smallest amount of characters that are not HTML tags that must be present
 * before we assume a piece contains actual text content.
 */
const MIN_TEXT_CONTENT_LENGTH = 5;

/**
 * Model representing a single component/attachment within a work of art.
 */
export class WorkPieceModel {
  readonly content: string | null;
  readonly id: string;
  readonly name: string;
  readonly workId: string;
  readonly upload: string | null;
  readonly url: string | null;
  private readonly store: AppStore;

  constructor(response: WorkPieceApiResponse, store: AppStore) {
    this.id = response.id;
    this.content = response.content;
    this.name = response.name;
    this.workId = response.work;
    this.upload = response.upload ?? null;
    this.url = response.url ?? null;
    this.store = store;
  }

  @memoize()
  get contentType(): ResourceContentType {
    if (this.content && isContentMeaningfullyLong(this.content)) {
      return ResourceContentType.TEXT_FILE;
    }
    return getResourceContentType(this.url ?? this.upload ?? "");
  }

  @memoize()
  get vimeoUrl(): string | null {
    const idMatches = this.url?.match(/vimeo\.com\/(.+)$/);
    if (idMatches && idMatches[1]) return this.url;
    return null;
  }

  @memoize()
  get work(): WorkModel | null {
    return this.store.works.find((work) => work.id === this.workId) ?? null;
  }
}

function isContentMeaningfullyLong(string: string) {
  return stripHtmlTags(string).length >= MIN_TEXT_CONTENT_LENGTH;
}

function stripHtmlTags(string: string) {
  return string.replace(/<[^>]*>/g, "");
}
