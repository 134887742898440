import { MediumApiResponse } from "data/api";

/**
 * Model representing an artistic medium.
 */
export class MediumModel {
  id: string;
  name: string;

  constructor(response: MediumApiResponse) {
    this.id = response.id;
    this.name = response.name;
  }
}
