import { EssayApiResponse } from "data/api";

/**
 * Model representing a single essay.
 */
export class EssayModel {
  readonly author: string;
  readonly authorsData: Author[];
  readonly content: string | null;
  readonly featured: boolean;
  readonly id: string;
  readonly title: string;

  constructor(response: EssayApiResponse) {
    this.author = response.author;
    this.authorsData = response.authors_data.map((author) => ({
      bio: author.bio,
      bioPhoto: author.bio_photo,
      latitude: author.latitude,
      longitude: author.longitude,
      location: author.location,
      locationPhoto: author.location_photo,
      name: author.name,
    }));
    this.content = response.content;
    this.featured = response.featured;
    this.id = response.id;
    this.title = response.title;
  }
}

interface Author {
  bio: string;
  bioPhoto: string;
  latitude: number;
  longitude: number;
  location: string;
  locationPhoto: string;
  name: string;
}
