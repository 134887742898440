import { EssayExcerptApiResponse } from "data/api";

/**
 * Model representing a single essay excerpt.
 */
export class EssayExcerptModel {
  readonly author: string;
  readonly excerpt: string | null;
  readonly featured: boolean;
  readonly id: string;
  readonly slug: string;
  readonly title: string;

  constructor(response: EssayExcerptApiResponse) {
    this.author = response.author;
    this.excerpt = response.excerpt;
    this.featured = response.featured;
    this.id = response.id;
    this.slug = response.slug;
    this.title = response.title;
  }
}
