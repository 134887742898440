export interface GeoCoordinate {
  lat: number;
  lng: number;
}

export interface Distance {
  meters: number;
  kilometers: number;
  miles: number;
}

/**
 * Sum 2 or more distances
 *
 * @param distances all values to add
 */
export function sumDistance(distances: Distance[]): Distance {
  return {
    meters: sum(distances.map((d) => d.meters)),
    kilometers: sum(distances.map((d) => d.kilometers)),
    miles: sum(distances.map((d) => d.miles)),
  };
}

/**
 * Convert meters to kilometers
 *
 * @param m Meters.
 */
export function metersToKm(m: number): number {
  return m / 1000;
}

/**
 * Convert kilometers to miles.
 *
 * @param km Kilometers.
 */
export function kmToMiles(km: number): number {
  return km * 0.62137;
}

/**
 * Calculate the distance between two coordinates.
 *
 * @param coord1 The first coordinate.
 * @param coord2 The second coordinate.
 */
export function distance(
  coord1: GeoCoordinate,
  coord2: GeoCoordinate
): Distance {
  const lat1 = coord1.lat;
  const lon1 = coord1.lng;
  const lat2 = coord2.lat;
  const lon2 = coord2.lng;
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const meters = R * c;
  const kilometers = metersToKm(meters);
  const miles = kmToMiles(kilometers);

  return {
    meters: Math.round(meters),
    kilometers: Math.round(kilometers),
    miles: Math.round(miles),
  };
}

function sum(numbers: number[]) {
  return numbers.reduce((total: number, current: number) => total + current, 0);
}
