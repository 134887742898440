/**
 * @param callback function to throttle
 * @param wait time between function calls
 * @param immediate `true` if the function should not wait to fire on the first call
 */
export function throttle(
  callback: () => void,
  wait: number,
  immediate = false
): () => void {
  let timeout: number | null = null;
  let initialCall = true;

  return function (this: unknown, ...args) {
    const callNow = immediate && initialCall;
    const next = () => {
      callback.apply(this, args);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timeout) {
      timeout = (setTimeout(next, wait) as unknown) as number;
    }
  };
}
