import { FunctionalComponent, h } from "preact";
import { Route, Router } from "preact-router";
import { useEffect } from "preact/hooks";

import styles from "./style.scss";

import AppLoader from "components/app/appLoader";
import Header from "components/header";
import { StoreContext } from "contexts";

import About from "routes/about";
import Artist from "routes/artist";
import Credits from "routes/credits";
import Emails from "routes/emails";
import Essay from "routes/essay";
import Exhibit from "routes/exhibit";
import Home from "routes/home";
import NotFoundPage from "routes/notfound";
import Search from "routes/search";

import { AppStore } from "stores/AppStore";
import { routes } from "utils/urls";
import { trueViewportHeight } from "utils/css/trueViewportHeight";

export const appStore = new AppStore();
void appStore.init();

if (module.hot) {
  // tslint:disable-next-line:no-var-requires
  require("preact/debug");
}

appStore.isNavExpanded$.subscribe((isExpanded: boolean) => {
  document.body.setAttribute("data-nav-expanded", String(isExpanded));
});

const App: FunctionalComponent = () => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        document.body.setAttribute("data-tabbing", "true");
      }
    };

    const onPointerDown = () => {
      document.body.setAttribute("data-tabbing", "false");
    };

    if (typeof window !== "undefined") {
      window.addEventListener("keydown", onKeyDown);
      window.addEventListener("pointerdown", onPointerDown);

      return () => {
        window.removeEventListener("keydown", onKeyDown);
        window.removeEventListener("pointerdown", onPointerDown);
      };
    }
  });

  useEffect(() => {
    const stopUpdatingViewportHeight = trueViewportHeight();

    return () => {
      stopUpdatingViewportHeight();
    };
  });

  return (
    <div class={styles.root} id="app">
      <StoreContext.Provider value={appStore}>
        <div class={styles.header}>
          <Header />
        </div>
        <main class={styles.main}>
          <Router>
            {/** Landing Pages. */}
            <Route path={routes.about.toRouterString()} component={About} />
            <Route path={routes.credits.toRouterString()} component={Credits} />
            <Route path={routes.emailUpdates.toRouterString()} component={Emails} />
            <Route path={routes.exhibit.toRouterString()} component={Exhibit} />
            <Route path={routes.home.toRouterString()} component={Home} />
            <Route path={routes.search.toRouterString()} component={Search} />

            {/** Detail Pages. */}
            <Route
              path={routes.artistDetail.toRouterString()}
              component={Artist}
            />
            <Route
              path={routes.essayDetail.toRouterString()}
              component={Essay}
            />

            {/** Misc Routes. */}
            <NotFoundPage default />
          </Router>
        </main>
        <AppLoader />
      </StoreContext.Provider>
    </div>
  );
};

export default App;
