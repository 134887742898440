import { WorkModel } from "./WorkModel";

import { Distance, distance } from "utils/geo/geo";
import { AppStore } from "stores/AppStore";
import { ArtistApiResponse } from "data/api";
import { memoize } from "utils/observable/memoize";
import { routes } from "utils/urls";

/**
 * Model representing an artist.
 */
export class ArtistModel {
  private readonly store: AppStore;
  readonly city: string;
  readonly country: string;
  readonly firstName: string;
  readonly id: string;
  readonly lastName: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly slug: string;
  readonly slugs: string[];
  readonly state: string | null;
  readonly url: string | null;

  constructor(response: ArtistApiResponse, store: AppStore) {
    this.city = response.city;
    this.country = response.country;
    this.firstName = response.first_name;
    this.id = response.id;
    this.lastName = response.last_name;
    this.latitude = response.latitude;
    this.longitude = response.longitude;
    this.slug = response.slug;
    this.slugs = response.slugs;
    this.state = response.state ?? null;
    this.url = response.url ?? null;
    this.store = store;
  }

  get bio(): string | null {
    return (
      this.work?.getPiece("bio")?.content ??
      this.work?.getPiece("stuff")?.content ??
      null
    );
  }

  @memoize()
  distanceFromArtist(artist: ArtistModel): Distance {
    return distance(
      {
        lat: this.latitude,
        lng: this.longitude,
      },
      {
        lat: artist.latitude,
        lng: artist.longitude,
      }
    );
  }

  @memoize()
  get formattedLocation(): string {
    return `${this.city}, ${this.country}`;
  }

  @memoize()
  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  @memoize()
  get link(): string {
    return routes.artistDetail.toLink({
      slug: this.slug,
    });
  }

  get locationPhoto(): string | null {
    return this.work?.getPiece("location")?.upload ?? null;
  }

  get profilePhoto(): string | null {
    return this.work?.getPiece("profile")?.upload ?? null;
  }

  @memoize()
  get work(): WorkModel | null {
    return this.store.works.find((work) => work.artist === this) ?? null;
  }

  async loadFull(): Promise<void> {
    await this.work?.loadPieces();
  }
}
